/***** Base rules *****/
html, body {
	height: 100%;
}

html {
	font-size: $em-base;
	@media #{$tablet} {
		font-size: $em-base*.75;
	}
	@media #{$mobile}{
		font-size: $em-base;
	}
}

body {
	@include font-smoothing;
	color: #000;
	background-color: #fff;
	font-family: $ep-font-sans;
	line-height: 1.2;
}

h1, h2, h3, h4, h5, h6 {
	font-family: $ep-font-serif;
	font-weight: normal;
}

h2 {
	text-align: center;
	font-size: 2rem;
	margin: 1em 0;
	@media #{$mobile}{
		font-size: 1.5rem;
	}
}

a {
	color: $ep-color-orange;
}

strong {
	font-weight: 600;
}

.button, button, input[type="submit"], input[type="text"], input[type="email"], textarea {
	box-sizing: border-box;
	padding: 0.5em $margin/2;
	border: 1px solid transparent;
	border-radius: 3px;
	display: inline-block;
	&.outline {
		border-color: $ep-color-orange;
	}
}

.button, button, input[type='submit'] {
	padding-left: $margin;
	padding-right: $margin;
	font-weight: bold;
	background-color: $ep-color-orange;
	color: #fff;
	&.outline {
		transition: background-color .15s;
		font-weight: normal;
		background-color: #fff;
		color: $ep-color-orange;
		&:hover {
			background-color: $ep-color-orange;
			color: #fff;
		}
	}
}

a.button {
	text-decoration: none;
}
/***** Base rules end *****/
