header {
	background-color: $ep-color-blue;
	width: 25%;
	position: fixed;
	top: $top-bar-height;
	bottom: 0;
	left: 0;
	box-sizing: border-box;
	padding: 1rem 2.5rem;
	@media #{$tablet} {
		width: 33.33%;
	}
	@media #{$mobile} {
		width: 100%;
		min-width: $min-width;
		bottom: auto;
		padding: .5rem 1.25rem;
	}
	h1 {
		text-align: center;
		img {
			@include img-responsive();
		}
		@media #{$mobile} {
			width: 8rem;
			margin: 0;
			float: left;
			line-height: 0;
		}
	}
	.worldwide-offices {
		color: mix(white, $ep-color-blue, 60);
		padding: 10rem 0;
		&::after {
			margin: 0 -2.5rem;
			content: '';
			display: block;
			height: 50rem;
			background: transparent url(../images/el-patio-offices-world-map-sidebar.gif) 20% top no-repeat;
			background-size: 90%;
		}
		p {
			&:first-child {
				margin-top: .5rem;
			}
		}
		@media #{$tablet} {
			padding: 4rem 0;
		}
		@media #{$medium} {
			padding: 2rem 0;
		}
		@media #{$mobile} {
			display: none !important;
		}
	}
}

.main-menu {
	@include list-unstyled();
	text-align: left;
	@media #{$mobile} {
		display: inline-block;
		float: right;
		text-align: right;
	}
	.projects-submenu {
		@media #{$short} {
			display: none;
		}
		@media #{$mobile} {
			display: none;
		}
	}
	li, a {
		transition: .15s;
	}
	a {
		color: $light-gray;
		text-decoration: none;
		display: block;
	}
	& > li > a {
		font-size: 1rem;
		@media #{$tablet} {
			font-size: 1.25rem;
			line-height: 2rem;
		}
		@media #{$mobile} {
			font-size: .75rem;
			line-height: 1.3rem;
		}
		color: #fff;
		text-transform: uppercase;
		line-height: 1.66rem;
	}
	li.visited {
		opacity: .4;
	}
	li.active,
	li:hover {
		transition: .15s;
		opacity: 1;
		a {
			color: #fff;
		}
	}
	ul {
		padding-left: .5rem;
		list-style-type: none;
		li {
			a {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				padding-bottom: .25rem;
				padding-right: .75rem;
				&::before {
					content: '· ';
					color: $light-gray;
				}
			}
			&.active a {
				cursor: default;
			}
			&.active a::after,
			a:hover::after {
				content: ' >';
				position: absolute;
				right: 2.5rem;
				margin-top: .125rem;
			}
			a:hover::after {
				color: $light-gray;
			}
			&.active a::after {
				color: #fff;
			}
		}
	}
}
