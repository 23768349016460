section.portfolio-section {
	padding-bottom: 0;

	.projects-index {
		@include list-unstyled(true);
		li {
			width: 33.33%;
			vertical-align: top;
			line-height: 0;
			@media #{$tablet} {
				width: 50%;
			}
			&.visited {
				a {
					img {
						// opacity: .35;
					}
					&::after {
						content: '';
						position: absolute;
						left: 0;
						top: 0;
						right: 0;
						bottom: 0;
						background-color: rgba(0, 0, 0, .5);
					}
				}
			}
			a {
				position: relative;
				opacity: 1;
				display: inline-block;
				&:hover {
					.label {
						transition: opacity .2s;
						opacity: 1;
					}
				}
				.label {
					opacity: 0;
					position: absolute;
					left: 0;
					top: 0;
					right: 0;
					bottom: 0;
					background-color: rgba(0, 0, 0, .75);
					text-align: center;
					color: #fff;
					h3 {
						font-family: $ep-font-sans;
						font-weight: 600;
						margin-top: 35%;
						line-height: 1em;
						text-transform: uppercase;
						@media #{$tablet} {
							margin-top: 30%;
						}
						@media #{$mobile} {
							margin-top: 25%;
						}
					}
					.client {
						display: block;
						margin-top: -0.5em;
						font-size: .875em;
					}
				}
			}
			img {
				@include img-responsive(true);
			}
		}
	}
}
