.about-section {
	overflow: hidden;
	min-height: 33.33vh;
	.wrapper {
		padding: 0 17.5%;
		@media #{$tablet} {
			padding: 0 12.5%;
		}
		@media #{$mobile} {
			padding: 0 $margin;
		}
	}
	p {
		font-size: 1.125rem;
	}
}
