@mixin font-smoothing {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
	-webkit-text-size-adjust: 100%;
}

@mixin list-unstyled($inline:false) {
	padding: 0;
	margin: 0;
	list-style: none;
	> li::before {
		content: none;
		margin: 0;
	}
	@if ($inline) {
		> li {
			display: inline-block;
		}
	}
}

@mixin img-responsive($full-extent:false) {
	@if ($full-extent) {
		width: 100%;
	}@else{
		max-width: 100%;
	}
	height: auto;
}

@mixin scrollable-absolute-area() {
	box-sizing: border-box;
	overflow: auto;
	position: absolute;
	top: $top-bar-height;
	bottom: 0;
	right: 0;
	width: 75%;
	@media #{$tablet} {
		width: 66.66%;
	}
	@media #{$mobile} {
		top: 89px;
		width: 100%;
	}
}

@mixin hide-text {
	overflow: hidden;

	&:before {
		content: "";
		display: block;
		width: 0;
		height: 100%;
	}
}
