// El Patio Font Family
@import url('//fonts.googleapis.com/css?family=Source+Sans+Pro:400,600|Source+Serif+Pro');
$ep-font-sans: 'Source Sans Pro', sans-serif;
$ep-font-serif: 'Source Serif Pro', serif;

// Patio Color Palette
$ep-color-dark-purple: #291C33;
$ep-color-purple: #9F63C2;
$ep-color-green: #90CC64;
$ep-color-orange: #F09818;
$ep-color-red: #EE3B51;
$ep-color-blue: #21A4D9;

$light-gray: #ededed;
$gray: #a5a5a5;
$dark-gray: #7F7F7F;
$light-purple: tint($ep-color-dark-purple, 85%);

$em-base: 16px;

$margin: 1.25rem;
$max-width: 60rem - 2 * $margin;
$min-width: 320px;

$top-bar-height: .75rem;

$tablet: 'only screen and (max-width: 1024px)';
$medium: 'only screen and (max-width: 767px)';
$mobile: 'only screen and (max-width: 520px)';
$short: 'only screen and (max-height: 500px)';
