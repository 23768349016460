.project-profile {
	@include scrollable-absolute-area();
	display: none;
	background-color: #fff;
	padding: 0 5rem;
	@media #{$medium} {
		padding: 0 2.5rem;
	}
	@media #{$mobile} {
		padding: 0 1.25rem;
	}
	overflow: auto;
	&.is-visible {
		display: block;
	}
	h2 {
		text-align: center;
		margin: 1.5em 0 0;
		@media #{$medium} {
			padding: 0 1.5rem;
		}
		@media #{$mobile} {
			margin-top: 1.35em;
			font-size: 1.5rem;
			margin-bottom: 0.15em;
		}
	}
	p {
		float: left;
		width: 50%;
		box-sizing: border-box;
		padding-right: 1.5rem;
		margin: 0 0 1.5em;
		@media #{$medium} {
			width: 100%;
			padding-right: 0;
		}
		&.full-width {
			width: 100%;
			clear: both;
			text-align: center;
			padding: 0;
		}
		.label {
			display: block;
			color: $dark-gray;
			margin-bottom: .5em;
		}
	}
	.client {
		font-size: 1.125rem;
		margin: 0 0 3rem;
		@media #{$mobile} {
			margin-bottom: 2rem;
		}
		a {
			text-decoration: none;
		}
		.label {
			display: inline;
			margin-right: 5px;
			span[lang]::after {
				content: ':';
			}
		}
	}
	a [lang=de] {
		opacity: .6;
	}
	nav {
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		text-align: center;
		a {
			display: inline-block;
			&.prev, &.next, &.close {
				@include hide-text();
				top: 1.66rem;
				width: 5rem;
				height: 5rem;
				@media #{$medium} {
					width: 6rem;
				}
				@media #{$mobile} {
					width: 3.5rem;
					top: .35rem;
				}
				background-repeat: no-repeat;
				background-position: 50% 50%;
				position: absolute;
				transition: opacity .15s, background-position .2s;
				opacity: .5;
				&:not([href]) {
					opacity: 0 !important;
				}
				&:hover {
					opacity: 1;
				}
			}
			&.prev {
				background-image: url(../images/left-arrow.svg);
				left: 0;
				&:hover {
					background-position: 47% 50%;
				}
			}
			&.next {
				background-image: url(../images/right-arrow.svg);
				right: 0;
				&:hover {
					background-position: 53% 50%;
				}
			}
			&.close {
				display: none;
				background-image: url(../images/close.svg);
			}
		}
		.numbers {
			display: none;
		}
	}
	.project-images {
		clear: both;
		padding-top: 2rem;
		@media #{$mobile} {
			padding-top: 1.5rem;
		}
		figure {
			margin: 0 0 5rem;
			@media #{$medium} {
				margin: 0 0 2.5rem;
			}
			img {
				@include img-responsive();
			}
			figcaption {
				margin-top: .5rem;
				text-align: center;
				font-style: italic;
				color: $dark-gray;
			}
		}
	}
}
