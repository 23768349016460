/***** Layout rules *****/
.wrapper {
	margin: 0 auto;
	padding: 0 $margin;
	max-width: $max-width;
}
/***** Layout rules end *****/



/***** Modules rules *****/
body {
	overflow: hidden;
	&::before {
		display: block;
		content: '';
		height: $top-bar-height;
		position: relative;
		z-index: 100;
		background: $ep-color-dark-purple url(../images/el-patio-color-bars.svg) repeat-y right center;
	}
}

.main-content {
	@include scrollable-absolute-area();
	> section {
		padding-bottom: 2rem;
	}
}

.motto {
	font-family: $ep-font-serif;
}

[lang=de] {
	opacity: .5;
}
/***** Modules rules end *****/



/***** Sections rules *****/
@import "header";

@import "about_section";

@import "portfolio_section";

@import "contact_section";

@import "footer";
/***** Sections rules end *****/
