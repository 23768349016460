footer {
	background-color: $light-gray;
	color: $ep-color-dark-purple;
	.wrapper {
		padding: 0.5em $margin;
		* {
			vertical-align: middle;
		}

		p {
			display: inline-block;
			padding-left: $margin / 4;
			margin: 0;
		}
	}
}
