// DO NOT EDIT DIRECTLY!
//Generated by gulp/tasks/iconFont.js
//from ./gulp/tasks/iconFont/template.sass.swig

@font-face {
  font-family: Post-Creator-Icons;
  src: url("fonts/Post-Creator-Icons.eot");
  src: url("fonts/Post-Creator-Icons.eot?#iefix") format('embedded-opentype'), url("fonts/Post-Creator-Icons.woff") format('woff'), url("fonts/Post-Creator-Icons.ttf") format('truetype'), url("fonts/Post-Creator-Icons.svg#Post-Creator-Icons") format('svg');
  font-weight: normal;
  font-style: normal; }

@mixin icon($content) {
  &:before {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    content: $content;
    font-family: 'Post-Creator-Icons';
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    line-height: 1;
    speak: none;
    text-transform: none;
    @content; } }

@mixin icon--facebook {
  @include icon("\e001") {
    @content; } }

.icon {
  &.-facebook {
    @include icon--facebook; } }

@mixin icon--linkedin {
  @include icon("\e002") {
    @content; } }

.icon {
  &.-linkedin {
    @include icon--linkedin; } }

@mixin icon--pinterest {
  @include icon("\e003") {
    @content; } }

.icon {
  &.-pinterest {
    @include icon--pinterest; } }

@mixin icon--twitter {
  @include icon("\e004") {
    @content; } }

.icon {
  &.-twitter {
    @include icon--twitter; } }


