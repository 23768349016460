section.contact-section {
	background-color: $ep-color-dark-purple;
	min-height: 480px;
	box-sizing: border-box;
	color: #fff;
	.wrapper {
		overflow: hidden;
		background: transparent url(../images/el-patio-offices-world-map.gif) center bottom no-repeat;
		background-size: 90% auto;
		padding-bottom: 6rem;

		form {
			input[type="text"], input[type="email"], textarea {
				width: 100%;
				color: $ep-color-dark-purple;
			}
			.status {
				margin-top: -5px;
				color: $light-gray;
			}
			&.error {
				.status {
					color: $ep-color-orange;
				}
			}
		}
		.worldwide-offices {
			width: 66%;
			min-height: 240px;
			padding-top: 1rem;
			padding-bottom: 1rem;
			p {
				&:first-child {
					margin-top: .5rem;
				}
				font-size: rem(16px);
			}
			strong {
				color: #fff;
			}
			@media (min-width: 720px) {
				input[type="submit"]{
					width: 100%;
				}
			}
		}

		@media (min-width: 721px) {
			padding-bottom: 0;
			background-size: 45%;
			background-position: 95% bottom;

			form, .worldwide-offices {
				width: 50%;
				float: left;
				display: inline-block;
				box-sizing: border-box;
			}
			.worldwide-offices {
				padding-left: $margin;
			}
		}

		@media (min-width: 1025px) {
			background-position: right 50%;
			form {
				width: 33.3%;
			}
			.worldwide-offices {
				padding-left: 2 * $margin;
				width: 66.6%;
			}
		}
	}
}
